import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import "./Dashboard.css";
import SessionService from '../services/SessionService';

function Session({userProfile}) {

    const dataEndpoint = process.env.REACT_APP_DATASERVICE_HOST;
    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [newSession, setNewSession] = useState({title: '', description: ''});
    const [isCreateOpen, setIsCreateOpen] = useState(false);
    const navigate = useNavigate();
    const [selectedSessionClosed, setSelectedSessionClosed] = useState(false);
    const goToActiveSessions = () => {
        navigate('/');
    };

    useEffect(() => {
        const getSessions = async () => {
            const data = await SessionService.getSessions(dataEndpoint);
            setSessions(data)
        };
        getSessions();
    }, [dataEndpoint]);

    const openModal = async (session) => {
        setSelectedSession(session);
        setIsModalOpen(true);
        try {
            const response = await SessionService.getSessionEvent(dataEndpoint, session.id);
            const eventData = await response;
            if (eventData.type === "end") {
                setSelectedSessionClosed(true)
            } else {
                setSelectedSessionClosed(false)
            }

        } catch (error) {
            console.error('Error while polling for events:', error);
        }
    };

    const closeModal = () => {
        setSelectedSession(null);
        setIsModalOpen(false);
    };

    const openCreateForm = () => {
        setNewSession({name: '', details: '', url: ''});
        setIsCreateOpen(true);
    };

    const closeCreateForm = () => {
        setIsCreateOpen(false);
    };

    const handleEnd = async (sessionId) => {
        let eventData = {
            "type": "end",
        }
        try {
            const response = await SessionService.generateEvent(dataEndpoint, sessionId, eventData);
            const responseData = await response;

        } catch (error) {
            console.error('Error while polling for events:', error);
        }
    };

    const handleDelete = async (sessionId) => {
        try {
            await SessionService.deleteSession(dataEndpoint, sessionId);
            setSessions(sessions.filter((session) => session.id !== sessionId));
            closeModal();
        } catch (error) {
            console.error('Error deleting session:', error);
        }
    };

    const handleJoin = async (sessionId) => {
        console.log(`Joining session ${sessionId}`);
        closeModal();
        try {
            const redirect = await SessionService.getSessionEvent(dataEndpoint, sessionId);
            const createdSession = await redirect;
            closeModal();
            console.log(`Got Back ${redirect}`)
            window.location.href = `${redirect.url}?session=${sessionId}`
        } catch (error) {
            console.error('Error deleting session:', error);
        }
    };

    const handleCreate = async () => {
        try {
            const response = await SessionService.createSession(dataEndpoint, newSession);
            const createdSession = await response;
            setSessions([...sessions, createdSession]);
            closeCreateForm();
        } catch (error) {
            console.error('Error creating session:', error);
        }
    };


    return (
        <div className={`dashboard ${userProfile["theme"]}`}>
            <div className="dashboard-box">
                <button className="short" onClick={goToActiveSessions}>back
                </button>
                <h1>Active Sessions</h1>
                <button onClick={openCreateForm}>Create New Session</button>
                <ul>
                    {sessions && sessions.map((session) => (
                        <li key={session.id} onClick={() => openModal(session)} style={{cursor: 'pointer'}}>
                            {session.title}
                        </li>
                    ))}
                </ul>

                {isModalOpen && selectedSession && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>{selectedSession.title}</h2>
                            <p>Created by: {selectedSession.owner}</p>
                            <p>Created on: {selectedSession.created}</p>
                            <p>{selectedSession.description}</p>
                            {selectedSessionClosed && (<p>Session has been closed</p>)}

                            <div className="modal-actions">
                                <button onClick={() => handleJoin(selectedSession.id)}>Join Session</button>
                                <button onClick={() => handleEnd(selectedSession.id)}>End Session</button>
                                <button onClick={() => handleDelete(selectedSession.id)}>Delete Session</button>
                                <button onClick={closeModal}>Close</button>
                            </div>
                            <p>Id: {selectedSession.id}</p>
                        </div>
                    </div>
                )}

                {isCreateOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <h2>Create New Session</h2>
                            <label>
                                Title:
                                <input
                                    type="text"
                                    value={newSession.title}
                                    onChange={(e) => setNewSession({...newSession, title: e.target.value})}
                                />
                            </label>
                            <label>
                                Details:
                                <textarea
                                    value={newSession.description}
                                    onChange={(e) => setNewSession({...newSession, description: e.target.value})}
                                    rows="5"
                                    cols="40"
                                    placeholder="Enter session details here..."
                                />
                            </label>
                            <div className="modal-actions">
                                <button onClick={handleCreate}>Create</button>
                                <button onClick={closeCreateForm}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}

                <style jsx>{`
                    ul {
                        list-style-type: none;
                        padding: 0;
                    }

                    li {
                        padding: 10px;
                        margin: 5px 0;
                        background: #f4f4f4;
                        border: 1px solid #ddd;
                        border-radius: 5px;
                    }

                    .modal {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.5);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    .modal-content {
                        background: #fff;
                        padding: 20px;
                        border-radius: 5px;
                        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
                    }

                    .modal-actions button {
                        margin: 5px;
                    }
                `}</style>
            </div>
        </div>
    );
}

export default Session;