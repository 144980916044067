import axios from 'axios';

class AuthService {
    static async login(endpoint, username, password) {
        try {
            const response = await axios.post(`${endpoint}/v1/login`,
                {
                    username: username,
                    password: password
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                });
            return response.status === 200;
        } catch (error) {
            await new Promise(resolve => setTimeout(resolve, 2000));
            return false;
        }
    }

    static async isAuthenticated(endpoint) {
        try {
            const response = await axios.get(`${endpoint}/v1/validate`,
                {
                    withCredentials: true
                });
            return response.status === 200;
        } catch (error) {
            console.log('Unauthorized');
            return false;
        }
    }

    static async getProfile(endpoint) {
        try {
            const response = await axios.get(`${endpoint}/v1/profile`,
                {
                    withCredentials: true
                });
            return response.data
        } catch (error) {
            console.log('Unauthorized');
            return false;
        }
    }

    static async setProfile(endpoint, profile) {
        try {
            const response = await axios.post(`${endpoint}/v1/profile`,
                profile,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    withCredentials: true
                });
            return response
        } catch (error) {
            console.log('Unauthorized');
            return false;
        }
    }

    static async logout(endpoint) {
        try {
            await axios.post(`${endpoint}/v1/logout`, {}, {
                withCredentials: true
            });

            window.location.href = "/";
        } catch (error) {
            console.error('Logout failed', error);
        }
    }
}

export default AuthService;
