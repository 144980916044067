import axios from 'axios';

class SessionService {
    static async getSessions(endpoint) {
        try {
            const response = await axios.get(`${endpoint}/v1/sessions`,
                {
                    withCredentials: true
                });
            return response.data
        } catch (error) {
            console.log('Unauthorized');
            return false;
        }
    }

    static async getSession(endpoint, session_id) {
        try {
            const response = await axios.get(`${endpoint}/v1/session/${session_id}`,
                {
                    withCredentials: true
                });
            return response
        } catch (error) {
            console.log('Unauthorized');
            return false;
        }
    }

    static async createSession(endpoint, session) {
        try {
            const response = await axios.post(`${endpoint}/v1/session`,
                session,
                {
                    withCredentials: true
                });
            return response.data
        } catch (error) {
            console.log('Unauthorized');
            return false;
        }
    }

    static async deleteSession(endpoint, session_id) {
        try {
            const response = await axios.delete(`${endpoint}/v1/session/${session_id}`,
                {
                    withCredentials: true
                });
            return response
        } catch (error) {
            console.log('Unauthorized');
            return false;
        }
    }

    static async getSessionEvent(endpoint, session_id) {
        try {
            const response = await axios.get(`${endpoint}/v1/sessionevent/${session_id}`,
                {
                    withCredentials: true
                });
            return response.data
        } catch (error) {
            console.log('Unauthorized');
            return false;
        }
    }

    static async generateEvent(endpoint, session_id, event_data) {
        try {
            const response = await axios.post(`${endpoint}/v1/sessionevent/${session_id}`,
                event_data,
                {
                    withCredentials: true
                });
            return response.data
        } catch (error) {
            console.log('Unauthorized');
            return false;
        }
    }
}

export default SessionService;
