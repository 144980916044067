import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import "./Dashboard.css";
import SessionService from '../services/SessionService';

function Options({userProfile, username, handleUpdateProfile, handleUpdateDisplay}) {
    const navigate = useNavigate();
    const goToActiveSessions = () => {
        navigate('/');
    };

    return (
        <div className={`dashboard ${userProfile["theme"]}`}>
            <div className="profile-container">
                <div className="dashboard-box">
                    <button className="short" onClick={goToActiveSessions}>back
                    </button>
                    <div className="profile-box">
                        Welcome {username}
                    </div>
                    <div className="profile-box">
                        <h2>Theme</h2>
                        <button onClick={() => handleUpdateProfile("theme")}>
                            Set {userProfile["theme"] === "light" ? "Dark" : "Light"} Mode
                        </button>
                    </div>
                    <div className="profile-box">
                        <h2>Playsheet Styles</h2>
                        <div className="profile-container">
                            <button onClick={() => {
                                handleUpdateDisplay(true, true, true, true);
                            }}
                                    className='button overlay-button img-everything'>
                            </button>
                            <button onClick={() => {
                                handleUpdateDisplay(false, true, true, true);
                            }}
                                    className='button overlay-button img-playsheet'>
                            </button>
                            <button onClick={() => {
                                handleUpdateDisplay(true, false, false, true);
                            }}
                                    className='button overlay-button img-plain'>
                            </button>
                            <button onClick={() => {
                                handleUpdateDisplay(false, false, false, false);
                            }}
                                    className='button overlay-button img-lyrics'>
                            </button>
                        </div>
                    </div>
                    <div className="profile-box">
                        <h2>Detailed Styles</h2>
                        <div className="profile-container">
                            <button onClick={() => handleUpdateProfile("show_chord_notation")}
                                    className={userProfile["show_chord_notation"] ? 'button-active overlay-button' : 'button  overlay-button'}>
                                Show Chord Notation
                            </button>
                            <button onClick={() => handleUpdateProfile("show_lyric_notation")}
                                    className={userProfile["show_lyric_notation"] ? 'button-active overlay-button' : 'button  overlay-button'}>
                                Show Chords in Lyrics
                            </button>
                            <button onClick={() => handleUpdateProfile("show_pins")}
                                    className={userProfile["show_pins"] ? 'button-active overlay-button' : 'button  overlay-button'}>
                                Show Pinned Chords
                            </button>
                            <button onClick={() => handleUpdateProfile("show_annotations")}
                                    className={userProfile["show_annotations"] ? 'button-active overlay-button' : 'button  overlay-button'}>
                                Show Annotation
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Options;