import React, {useState, useEffect} from 'react';
import "./LoginPage.css"
import "./Themes.css"
import "./Common.css"

function LoginPage({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onLogin(username, password);
    };

    return (
        <div className="login-page">
            <h2>MusicSheetz</h2>
            <div className="flavor-graphic"></div>
            <form onSubmit={handleSubmit}>
                <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
                <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                <button className="light" type="submit">Login</button>
            </form>
        </div>
    );
}

export default LoginPage;
