import React from 'react';
import {useNavigate} from 'react-router-dom';
import "./Dashboard.css";

function Dashboard({
                       onLogout,
                       setlist_redirect,
                       dataloader_redirect,
                       userProfile,
                   }) {
    const navigate = useNavigate();
    const goToActiveSessions = () => {
        navigate('/sessions');
    };
    const goToOptiona = () => {
        navigate('/options');
    };
    return (

        <div className={`dashboard ${userProfile["theme"]}`}>
            <div className="profile-container">
                <div className="dashboard-box">
                    <h2>MusicSheetz</h2>
                    <div className="flavor-graphic"></div>
                </div>
                <div className="dashboard-box">
                    <h2>Actions</h2>
                    <div className="profile-box">
                        <button onClick={() => window.location.href = setlist_redirect}>Setlists
                        </button>
                        <button onClick={goToActiveSessions}>Sessions
                        </button>
                        <button onClick={goToOptiona}>Display Options
                        </button>
                        <button onClick={() => window.location.href = dataloader_redirect}>Data
                            loading
                        </button>
                        <button onClick={onLogout}>Logout</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;